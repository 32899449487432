<template>
  <b-sidebar
    id="sidebar-task-handler"
    @change="
      (val) => {
        $emit('update:is-retainer-sidebar-active', val);
      }
    "
    sidebar-class="sidebar-lg"
    @shown="openSidebar()"
    @hidden="clearSidebarData()"
    :visible="isRetainerSidebarActive"
    bg-variant="white"
    shadow
    width="700px"
    backdrop
    right
  >
    <div>
      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col cols="12">
            <b-card>
              <span
                @click="activeTab = 1"
                :class="{
                  activeMenu: activeTab === 1,
                  passiveMenu: activeTab !== 1,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 "></i> Clockify
              </span>

              <div v-if="activeTab == 1">
                <div class="cardClass">
                  <div class="subTitle">Report</div>

                  <client-project-report
                    :client-id="clientId"
                    :start-date="startDate"
                    :end-date="endDate"
                    :clockify-reports="clockifyReports"
                  ></client-project-report>

                  <div class="subTitle mt-3">Actions</div>

                  <div class="input-row">
                    <label for="additionalHours">Description:</label>

                    <input
                      v-model="shortDescription"
                      type="text"
                      id="additionalHours"
                      class="w-20 mr-1"
                    />

                    <label for="additionalHours">Hours:</label>

                    <input
                      v-model="additionalHours"
                      type="number"
                      id="additionalHours"
                      placeholder="Enter hours"
                      class="w-20"
                    />

                    <label for="billable" class="ml-2">Billable:</label>
                    <input v-model="isBillable" type="checkbox" id="billable" />
                    <b-button
                      class="ml-1"
                      variant="primary"
                      size="sm"
                      @click="addRetainerHours"
                      >Add</b-button
                    >
                  </div>

                  <div class="cardClass mt-3" style="background-color: #f3f3f3;">
                    <div class="subTitle">Comments</div>

                    <retainer-notes-component :retainer-id="retainerId"></retainer-notes-component>

                  </div>

                  <div class="mt-3">
                    <b-button
                      block
                      variant="danger"
                      size="sm"
                      @click="cancelRetainer"
                      >Cancel this retainer</b-button
                    >
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { VBPopover, BSidebar } from "bootstrap-vue";
import ClientProjectReport from "./ClientProjectReport.vue";
import { formatDate, getAvatarText } from "../helpers";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormDatepicker } from "bootstrap-vue";

import axiosIns from "@/libs/axios";
import { formatTimeAgo, formatDateTime } from "../helpers";
import RetainerNotesComponent from "./RetainerNotesComponent.vue";

export default {
  components: {
    ClientProjectReport,
    ToastificationContent,
    quillEditor,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,

    BSidebar,
    RetainerNotesComponent
  },

  directives: {
    "b-popover": VBPopover,
  },

  model: {
    prop: "isRetainerSidebarActive",
    event: "update:is-retainer-sidebar-active",
  },

  props: {
    isRetainerSidebarActive: {
      type: Boolean,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    retainerId: {
      type: Number,
      required: true,
    },
    clockifyReports: {
      type: Array,
      required: true,
    },
  },

  data() {
    const descriptionOption = {
      modules: {
        toolbar: "#description-toolbar",
      },
      placeholder: "What is this task about?",
    };

    const blankTempTaskData = {
      id: null,
      assignedUser: null,
      additionalAssigneeUser: null,
      dueDate: null,
      description: null,
      projectId: parseInt(this.projectModalId),
    };

    const tempTaskData = JSON.parse(JSON.stringify(blankTempTaskData));

    const tempProjectId = parseInt(this.projectModalId);
    return {
      popoverShow: {},
      selectedUserId: null,
      selectedUserTitle: null,
      initialDescription: null,
      selectedContactId: null,
      selectedContactTitle: null,
      shortDescription: null,
      additionalHours: 0,
      isBillable: false,

      tempMemberRole: null,
      tempContactRole: null,
      users: [],
      contacts: [],
      clientNotes: [],
      clientDescription: null,
      editedDescription: null,
      descriptionOption,
      isNameEditing: false,
      isDescriptionEditing: false,
      loading: false,
      activeTab: 1,

      isTaskHandlerSidebarActive: false,
      projectData: null,
      blankTempTaskData,
      tempTaskData,

      taskFilters: [
        { title: "Critical", textColor: "#000000", bgColor: "#f06a6a" },
        { title: "High", textColor: "#000000", bgColor: "#ec8d71" },
        { title: "Medium", textColor: "#000000", bgColor: "#f1bd6c" },
        { title: "Low", textColor: "#000000", bgColor: "#b3df97" },
        { title: "Not a Priority", textColor: "#FFFFFF", bgColor: "#4573d2" },
      ],

      rightClickData: [],
      fileURL: store.state.app.fileURL,

      folderId: 0,
      modalFolderName: null,
      modalFiles: null,
      modalFile: [],
      percentCompleted: 0,
      userData: JSON.parse(localStorage.getItem("userData")),
      directoryPath: "",
      projectId: tempProjectId,
      folderTemp: [
        {
          text: "Main",
          id: 0,
          active: true,
          projectId: tempProjectId,
        },
      ],

      foldersData: [],
    };
  },

  mounted() {},
  watch: {},

  methods: {
    formatTimeAgo,
    formatDateTime,
    formatDate,
    getAvatarText,


    addRetainerHours() {

      if (this.additionalHours > 0 && this.shortDescription != null) {
        this.loading = true;

        store
          .dispatch("client/addRetainerHours", {
            description: this.shortDescription,
            retainerId: this.retainerId,
            hoursTotal: this.additionalHours,
            billable: this.isBillable,
          })
          .then((res) => {
            this.isBillable = false;
            this.shortDescription = null;
            this.additionalHours = 0;
            this.$emit("get-retainers");

            this.loading = false;

          })
          .catch((error) => {
            this.isBillable = false;
            this.shortDescription = null;
            this.additionalHours = 0;

            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "An error occurred",
                text: "Please try again later or contact support.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Missing Fields",
            text: "Please fill in the required details.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    cancelRetainer() {
      this.$swal({
        title: "Are you sure?",
        text: "Selected retainer will be deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("client/cancelRetainer", { id: this.retainerId })
            .then((res) => {
              this.loading = false;
              this.$emit("update:is-retainer-sidebar-active", false);
              this.$emit("get-retainers");
            })
            .catch((error) => {
              this.isEditing = null;
              this.editedTaskHours = null;

              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "An error occurred",
                  text: "Please try again later or contact support.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.contact:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact-details {
  flex-grow: 1;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

.contact-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.contact:hover .contact-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: "\2713";
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: "\2713";
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-97 {
  width: 97%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-43 {
  width: 43%;
}

.w-39 {
  width: 39%;
}
.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-51 {
  width: 51%;
}
.w-55 {
  width: 55%;
}
.w-25 {
  width: 25%;
}
.w-41 {
  width: 41%;
}

.w-35 {
  width: 35%;
}

.w-12 {
  width: 12%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-38 {
  width: 38%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: "\23F7";
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
  margin-bottom: 15%;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  gap: 5px;
  // padding: 50px;
}

.custom-card {
  // border: 1px solid #e0e0e0;
  // border-radius: 5px;
  // background-color: #f5f5f5;
  transition: background-color 0.2s ease; /* Hover efekti için geçiş ekledik */
}

.custom-card-content {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.custom-card:hover {
  background-color: #e0e0e080; /* Hover efekti için arka plan rengi değişiyor */
}

.custom-card-image {
  width: 64px;
}

.custom-card-footer {
  font-size: 12px;
  text-align: center;
  // padding: 5px 0;
}
.active a {
  color: #6e6b7b;
  cursor: auto;
  text-decoration: none;
}

.no-file-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}

.input-row {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border: 1px solid gray;
  padding: 5%;
}

.input-row label {
  margin-right: 10px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~vue-context/dist/css/vue-context.css";
</style>
